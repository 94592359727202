// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-about-page-template-jsx": () => import("./../../../src/templates/AboutPageTemplate.jsx" /* webpackChunkName: "component---src-templates-about-page-template-jsx" */),
  "component---src-templates-contact-page-template-jsx": () => import("./../../../src/templates/ContactPageTemplate.jsx" /* webpackChunkName: "component---src-templates-contact-page-template-jsx" */),
  "component---src-templates-home-page-template-jsx": () => import("./../../../src/templates/HomePageTemplate.jsx" /* webpackChunkName: "component---src-templates-home-page-template-jsx" */),
  "component---src-templates-product-page-template-jsx": () => import("./../../../src/templates/ProductPageTemplate.jsx" /* webpackChunkName: "component---src-templates-product-page-template-jsx" */),
  "component---src-templates-projects-page-template-jsx": () => import("./../../../src/templates/ProjectsPageTemplate.jsx" /* webpackChunkName: "component---src-templates-projects-page-template-jsx" */)
}

