import React from "react"
import ContactButton from "./ContactButton.jsx"
import Navigation from "./Navigation.jsx"
import ScrollTopButton from "./ScrollTopButton.jsx"
// import Footer from "./Footer.jsx"
// import CookieConsent from "./CookieConsent"

const Layout = ({ children }) => {
  return (
    <div
      id="layout-wrapper"
      className="relative overflow-hidden flex flex-col min-h-screen pb-10"
    >
      <Navigation />
      <main>{children}</main>
      {/* <Footer /> */}
      {/* <CookieConsent /> */}
      <ContactButton />
      <ScrollTopButton />
    </div>
  )
}

export default Layout
