import React from "react"
import NavigationNavbar from "./NavigationNavbar"
import LogoSvg from "./LogoSvg"
import { useStaticQuery, graphql, Link } from "gatsby"
import PageContext from "../../context/PageContext"
import useTabIsUsed from "../../hooks/useTabIsUsed"
import getClassNamesByTabIsUsedState from "../../helpers/getClassNamesByTabIsUsedState"

const Navigation = () => {
  const tabIsUsed = useTabIsUsed()
  const data = useStaticQuery(graphql`
    {
      en: contentfulMainMenu(node_locale: { eq: "en-US" }) {
        links: menuLink {
          id
          text
          slug
          big
        }
      }
      de: contentfulMainMenu(node_locale: { eq: "de" }) {
        links: menuLink {
          id
          text
          slug
          big
        }
      }
    }
  `)
  const { locale } = React.useContext(PageContext)
  const links = locale === "en-US" ? data.en.links : data.de.links
  return (
    <header className="bg-transparent z-50 flex px-4 py-3  fixed top-0 w-full justify-between">
      <div id="brand-container">
        {locale === "en-US" ? (
          <Link
            to="/"
            className={`${getClassNamesByTabIsUsedState(tabIsUsed)} block`}
          >
            <LogoSvg className="w-12 h-12 text-light" title="Home" />
          </Link>
        ) : (
          <Link
            to="/de/"
            className={`${getClassNamesByTabIsUsedState(tabIsUsed)} block`}
          >
            <LogoSvg className="w-12 h-12 text-light" title="Startseite" />
          </Link>
        )}
      </div>
      <NavigationNavbar links={links} data={data} tabIsUsed={tabIsUsed} />
    </header>
  )
}

export default Navigation
