import React from "react"

function LogoSvg(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 304 314"
      role="img"
    >
      <defs />
      <title id="uniqueTitleID">{props.title || "lichtraum"}</title>
      <path
        className="fill-current"
        d="M145 0h3l1 54v7l-5-54c0-2-2-7 1-7M137 1l1 3c2 19 3 39 6 58h-5L128 2l9-1M174 1c-3 20-5 42-10 61l2-30 3-30c1-2 4-1 5-1M214 12c-8 20-17 38-26 57a34 34 0 01-4-1l21-59 9 3M97 11l17 43 5 13c-2 0-3-3-4-6L93 13l4-2M224 17l-31 55c1-5 4-9 6-14l20-43 5 2zM105 74c-2 0-3-3-5-5L70 26l4-3 31 51zM246 31l-40 50c0-3 2-5 4-7l32-46a14 14 0 014 3M100 77c0 2-2 2-3 4L63 41l-5-6 7-6 35 48M275 61l-52 37c-2 0-2-2-3-3l49-42 6 8M82 96a104 104 0 01-13-8L32 62l3-4h1l41 33c2 2 4 2 5 5M62 159l-15 1H2v-5h1l53 3 6 1M281 70l-55 33 5-5 47-32a17 17 0 013 4M74 109l-7-2-48-23 2-4h1l49 27 3 2M293 93l-46 18-15 5 59-28a31 31 0 012 5zM71 114l-1 4-57-20 4-9 54 25M301 123l2 10c-22 2-43 5-65 6l-1-4 64-12M65 139l-30-2-30-3v-5h1l52 8c3 1 5 0 7 2M163 314h-4l-1-54c0-3-1-6 1-7l5 54c0 2 1 7-1 7M171 313c-2 0-1-2-1-3-3-19-4-39-6-58h5l10 60-8 1M133 313l10-61-2 30-3 31h-5M93 302l26-57a34 34 0 014 2l-21 59-9-4M210 302l-16-42c-2-5-5-9-5-14l3 6 22 48-3 2M83 298l31-55c-1 5-4 9-6 14l-20 43-5-2zM202 239c2 0 3 3 5 5l30 43c0 2-3 2-3 3l-32-51zM62 284l39-49c0 2-2 4-4 6l-32 46a14 14 0 01-4-3M207 236c0-2 2-2 3-4l35 40 5 6-8 6-35-48M32 255l52-38 3 3-49 42-6-7M225 217a104 104 0 0112 8l37 26-3 4v-1l-41-33c-2-1-5-2-5-4M26 246l55-33c-1 2-4 3-6 4l-46 33a17 17 0 01-3-4M233 204c2-1 4 1 6 2l48 22-2 5v-1l-50-26-2-2M14 223l45-18 15-5-58 27a31 31 0 01-2-4zM235 198l2-4 56 20-4 9-54-25M5 193l-2-10 65-7 1 5c-22 4-43 7-64 12M241 173l30 3 30 2a35 35 0 01-1 5l-53-8c-2 0-4 0-6-2M243 147c9-2 19-2 29-3l31-2v5l-53 1-7-1"
      />
    </svg>
  )
}

export default LogoSvg
