import React from "react"
import { Link } from "gatsby"
import getClassNamesByTabIsUsedState from "../../helpers/getClassNamesByTabIsUsedState"
import useTabIsUsed from "../../hooks/useTabIsUsed"
import PageContext from "../../context/PageContext"

const ContactButton = () => {
  const { locale } = React.useContext(PageContext)
  const tabIsUsed = useTabIsUsed()
  return (
    <Link
      className={`text-white bg-primary px-4 py-2 fixed bottom-2 left-2 z-50 inline-block mt-4 ${getClassNamesByTabIsUsedState(
        tabIsUsed
      )} focus:ring-white`}
      to={locale === "en-US" ? "/contact/" : "/de/contact/"}
    >
      Contact Us
    </Link>
  )
}

export default ContactButton
