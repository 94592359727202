import React from "react"
import SiteWrapper from "./src/components/reusables/SiteWrapper"
import PageContextProvider from "./src/context/PageContextProvider"

export const wrapRootElement = ({ element }) => (
  <SiteWrapper>{element}</SiteWrapper>
)

export const wrapPageElement = ({ element, props: { pageContext } }) => {
  return (
    <PageContextProvider pageContext={pageContext}>
      {element}
    </PageContextProvider>
  )
}
