import React from "react"
import { Link } from "gatsby"
import NavigationLangMenu from "./NavigationLangMenu"
import getClassNamesByTabIsUsedState from "../../helpers/getClassNamesByTabIsUsedState"

const NavigationNavbar = ({ links, tabIsUsed }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  React.useEffect(() => {
    function handleKeydown(e) {
      if (e.key === "Esc" || e.key === "Escape") {
        setIsOpen(false)
      }
    }
    document.addEventListener("keydown", handleKeydown)
    return () => {
      document.removeEventListener("keydown", handleKeydown)
    }
  }, [])
  return (
    <div className="relative my-auto ml-auto">
      <button
        className={`z-10 relative block w-8 text-midDark hover:text-light focus:text-light ${getClassNamesByTabIsUsedState(
          tabIsUsed
        )}`}
        aria-label="menu"
        onClick={() => {
          setIsOpen(isOpen => !isOpen)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {isOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          )}
        </svg>
      </button>
      {isOpen && (
        <>
          <button
            id="overlay"
            className="fixed inset-0 h-full w-full bg-black opacity-80 cursor-default focus:outline-none"
            tabIndex="-1"
            onClick={() => setIsOpen(false)}
            aria-label="layout"
          />
          <nav className="mt-2 py-2 bg-gradient-to-b to-gray-500 from-dark rounded-lg w-48 max-h-96 lg:max-h-screen absolute right-0 overflow-y-scroll">
            <ul>
              {links.map(({ id, text, slug, big }) => (
                <li key={id}>
                  <Link
                    to={slug}
                    activeClassName="text-primary"
                    className={`block px-4 py-2 hover:bg-light hover:text-dark ${
                      tabIsUsed ? "navbar-focus" : "focus:outline-none"
                    } ${big ? "text-lg" : "text-xs"}`}
                  >
                    {text}
                  </Link>
                </li>
              ))}
              <li>
                <NavigationLangMenu tabIsUsed={tabIsUsed} />
              </li>
            </ul>
          </nav>
        </>
      )}
    </div>
  )
}

export default NavigationNavbar
