import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import PageContext from "../../context/PageContext"
const LangLink = ({ currentLocale, locale, tabIsUsed, ...props }) => (
  <Link
    {...props}
    className={`block px-4 py-2 hover:bg-light hover:text-dark text-sm ${
      tabIsUsed ? "navbar-focus" : "focus:outline-none"
    } ${locale === currentLocale && "text-primary"} `}
  />
)

const NavigationLangMenu = ({ tabIsUsed }) => {
  const { locale } = React.useContext(PageContext)
  const { pathname } = useLocation()

  const data = useStaticQuery(graphql`
    {
      deEnglishMenuLink: contentfulMenuLink(
        title: { eq: "English Menu Link" }
        node_locale: { eq: "de" }
      ) {
        text
      }
      enEnglishMenuLink: contentfulMenuLink(
        title: { eq: "English Menu Link" }
        node_locale: { eq: "en-US" }
      ) {
        text
      }
      deGermanMenuLink: contentfulMenuLink(
        title: { eq: "German Menu Link" }
        node_locale: { eq: "de" }
      ) {
        text
      }
      enGermanMenuLink: contentfulMenuLink(
        title: { eq: "German Menu Link" }
        node_locale: { eq: "en-US" }
      ) {
        text
      }
    }
  `)
  return (
    <div className="border-t pt-2 mt-2">
      <LangLink
        to={locale === "en-US" ? "/de" + pathname : pathname}
        currentLocale={locale}
        locale="de"
        tabIsUsed={tabIsUsed}
      >
        {locale === "en-US"
          ? data.enGermanMenuLink.text
          : data.deGermanMenuLink.text}
      </LangLink>
      <LangLink
        to={locale === "de" ? pathname.replace("/de", "") : pathname}
        currentLocale={locale}
        locale="en-US"
        tabIsUsed={tabIsUsed}
      >
        {locale === "en-US"
          ? data.enEnglishMenuLink.text
          : data.deEnglishMenuLink.text}
      </LangLink>
    </div>
  )
}

export default NavigationLangMenu
